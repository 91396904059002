import React from 'react';
import { Link } from 'gatsby';

// Style imports
import styles from '../styles/components/panels.module.css';

// Component imports

function Panels(props) {
	// Background image
	let backgroundImage = '';
	if (props.backgroundImage !== '') {
		backgroundImage = props.backgroundImage;
	}

	let backgroundImageStyle = {
		backgroundColor: `${props.backgroundColor}`,
		backgroundImage: `url(${backgroundImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	};

	// Title
	let title = <h2>{props.title}</h2>;
	if (props.title === '') {
		title = '';
	}

	// Subtitle
	let subtitle = <h3>{props.subtitle}</h3>;
	if (props.subtitle === '') {
		subtitle = '';
	}

	// Button
	let button = props.panels.map((panel) => (
		<Link className={`btn btn-${panel.buttonType}`} href={panel.link}>
			{panel.cta}
		</Link>
	));
	if (props.panels.map((panel) => panel.cta === '')) {
		button = '';
	}

	return (
		<div id={props.id} className={`${styles.panels} ${styles[props.customClass]}`} style={backgroundImageStyle}>
			<div className="container">
				{title}
				{subtitle}
				<div className={styles.divider} />
			</div>
			<div className={`container ${styles.container}`}>
				{props.panels.map((panel) => (
					<div className={styles.panel}>
						<div className={styles.graphic}>{panel.graphic}</div>
						<div className={styles.text}>
							<h4>{panel.title}</h4>
							<p>{panel.description}</p>
							{button}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Panels;
