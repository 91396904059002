import React from 'react';
import { Link } from 'gatsby';

// Style imports
import styles from '../styles/components/brief.module.css';

// Component imports

function Brief(props) {
	// Background image
	let backgroundImage = '';
	if (props.backgroundImage !== '') {
		backgroundImage = props.backgroundImage;
	}

	let backgroundImageStyle = {
		backgroundColor: `${props.backgroundColor}`,
		backgroundImage: `url(${backgroundImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	};

	// Title
	let title = <h2>{props.title}</h2>;
	if (props.title === '') {
		title = '';
	}

	// Subtitle
	let subtitle = <h3>{props.subtitle}</h3>;
	if (props.subtitle === '') {
		subtitle = '';
	}

	// Button
	let button = (
		<Link className={`btn btn-${props.buttonType}`} to={props.link}>
			{props.cta}
		</Link>
	);
	if (props.cta === '') {
		button = '';
	}

	return (
		<div id={props.id} className={`${styles.brief} ${styles[`${props.type}`]}`} style={backgroundImageStyle}>
			<div className={styles.container}>
				<div className={styles.text}>
					{title}
					{subtitle}
					<div className={styles.divider} />
					{props.description}
					{button}
				</div>
			</div>
		</div>
	);
}

export default Brief;
