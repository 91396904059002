import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

// Layout import
import Layout from '../layouts/main';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/southern-edge-communications.jpg';

// Componenent imports
import Intro from '../components/intro';
import Panels from '../components/panels';
import Brief from '../components/brief';
import Tabs from '../components/tabs';

// Import styles
import styles from '../styles/pages/index.module.css';

function IndexPage() {
	// Tower image
	const data = useStaticQuery(graphql`
		query PanelImages {
			test: file(absolutePath: {}, relativePath: { eq: "tower.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
			icon1: file(absolutePath: {}, relativePath: { eq: "5g-node-installation.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
			icon2: file(absolutePath: {}, relativePath: { eq: "5g-mini-cell-installation.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
			icon3: file(absolutePath: {}, relativePath: { eq: "fiber-optics.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
			icon4: file(absolutePath: {}, relativePath: { eq: "cat-6-networks.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
			icon5: file(absolutePath: {}, relativePath: { eq: "edge-network-expansion.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	// Intro props
	let introProps = {
		title: 'Building excellence with the speed of light',
		description: '',
		cta: '',
		link: '/'
	};

	// Panels props
	let panelsProps1 = {
		id: 'services', // component ID
		customClass: 'panels1', // custom class
		format: 'left', // center, right, left
		backgroundImage: '', // pass background image here
		backgroundColor: 'var(--ink)', // background color
		title: 'What We Do', // section title
		subtitle: '', // section subtitle
		panels: [
			{
				graphic: <Img fluid={data.icon1.childImageSharp.fluid} alt="An illustration of a 5G tower." />, // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: (
					<Fragment>
						5G Node<br /> Installation
					</Fragment>
				), // panel title
				description: '', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: <Img fluid={data.icon2.childImageSharp.fluid} alt="An illustration of a 5G tower." />, // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: (
					<Fragment>
						5G Mini Cell<br /> Installations
					</Fragment>
				), // panel title
				description: '', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: <Img fluid={data.icon3.childImageSharp.fluid} alt="An illustration of a 5G tower." />, // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: (
					<Fragment>
						Fiber<br />Optics
					</Fragment>
				), // panel title
				description: '', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: <Img fluid={data.icon4.childImageSharp.fluid} alt="An illustration of a 5G tower." />, // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: (
					<Fragment>
						Cat 6<br /> Networks
					</Fragment>
				), // panel title
				description: '', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: <Img fluid={data.icon5.childImageSharp.fluid} alt="An illustration of a 5G tower." />, // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: (
					<Fragment>
						Edge Network<br /> Expansion
					</Fragment>
				), // panel title
				description: '', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			}
		]
	};

	// Brief props
	let briefProps = {
		id: 'about', // component ID
		backgroundImage: '', // pass background image here
		backgroundColor: 'var(--accessory)', // background color
		title: 'Who We Are',
		description: (
			<Fragment>
				<p>
					Southern Edge Communications, Inc. is proud to be a veteran owned business. We are focused on
					providing outstanding infrastructure services with the highest levels of customer satisfaction.
					Whether you are looking to expand 5G mini cells in public right away or needing better coverage
					within your commercial building, we will do everything we can to meet your expectations.
				</p>
				<p>
					With a variety of offerings to choose from, we’re sure you’ll be happy working with us. Look around
					our website and if you have any comments or questions, please feel free to contact us. We hope to
					see you again! Check back later for new updates to our website.
				</p>
				<p>There’s much more to come!</p>
			</Fragment>
		), // hero description
		cta: '', // text for button
		link: '/', // url for button
		buttonType: 'secondary' // primary, secondary, accent
	};

	// Tabs props
	let tabsProps = {
		id: 'leadership', // component ID
		customClass: '', // custom class
		class: 'customClass', // custom wrapper class
		backgroundImage: '', // pass background image here
		backgroundColor: 'var(--paper)', // background color
		marginBottom: '15',
		title: 'Leadership', // section title
		subtitle: '', // section subtitle
		tabs: [
			{
				id: 1,
				button: 'Earl Gill',
				name: 'Earl Gill',
				content: (
					<Fragment>
						<p data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
							Earl has an extensive background in communications with over 20 years' of experience in the
							telecom industry. At the young age of 16, he volunteered to serve our country in the US Navy
							as an Aviation Electronic Technician. At the age of 20, after seeing the world, Earl
							received an honorable discharge and began a rewarding career in the telecom industry. Over
							the years, he had the pleasure of working with all major service providers in cable, telecom
							and cellular industries with a focus on test and measurement. Additionally, he has
							facilitated several in-depth technology classes for CE certifications, as well as, best
							practices to frontline technicians. Earl earned a Bachelor of Science in Electrical
							Engineering from the University of Maryland. Go Terps!
						</p>
					</Fragment>
				)
			},
			{
				id: 2,
				button: 'Tim Reser',
				name: 'Tim Reser',
				content: (
					<Fragment>
						<p data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
							Vice President of Operation and current owner of PGA Construction. PGA Construction was
							founded in 2008 and became a licensed General Contractor in 2009. At the beginning, PGA
							Construction focused on residential projects before quickly moved into commercial projects
							upon securing a General Contractor license. In 2018, PGA Construction began working on 5G
							Node and Mini Cell installations with over 40 projects completed. Has a Bachelor of Science
							in Business Administration with a focus on Marketing from Farris State University.
						</p>
					</Fragment>
				)
			}
		]
	};

	// Panels props
	let panelsProps2 = {
		id: 'values', // component ID
		customClass: 'panels2', // custom class
		format: 'left', // center, right, left
		backgroundImage: '', // pass background image here
		backgroundColor: 'var(--primaryDark)', // background color
		title: '', // section title
		subtitle: 'Our Core Values', // section subtitle
		panels: [
			{
				graphic: '', // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: 'Respect for the individual', // panel title
				description: 'Each person is of equal value. Treat each person as you would want to be treated.', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: '', // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: 'Integrity', // panel title
				description: 'Be honest and truthful with everyone, both inside and outside the company.', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: '', // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: 'Continuous Improvement',
				description: 'Always look for ways to improve how we do things.', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: '', // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: 'Deilver Excellence',
				description: 'Exceed the customer’s expectations.', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			},
			{
				graphic: '', // nables/diables additional image
				graphicAltText: 'Test image', // Graphic alt text
				title: 'Balanced Life',
				description: 'Work smarter, not longer.', // hero description
				cta: '', // text for button
				link: '/', // url for button
				buttonType: 'secondary' // primary, secondary, accent
			}
		]
	};

	return (
		<Layout>
			<SEO
				title="Southern Edge Communications"
				description="Southern Edge Communications, Inc. is proud to be a veteran owned business. We are focused on providing outstanding infrastructure services with the highest levels of customer satisfaction. Whether you are looking to expand 5G mini cells in public right away or needing better coverage within your commercial building, we will do everything we can to meet your expectations."
				image={ogImage}
			/>
			<Intro {...introProps} />
			<Panels {...panelsProps1} />
			<Brief {...briefProps} />
			<Tabs {...tabsProps} />
			<Panels {...panelsProps2} />
		</Layout>
	);
}

export default IndexPage;
