import React, { useState } from 'react';

// Style imports
import styles from '../styles/components/tabs.module.css';

function Tabs(props) {
	// Background image
	let backgroundImage = '';
	if (props.backgroundImage !== '') {
		backgroundImage = props.backgroundImage;
	}

	let backgroundImageStyle = {
		backgroundColor: `${props.backgroundColor}`,
		backgroundImage: `url(${backgroundImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	};

	const [ isActive, setIsActive ] = useState(1);

	let tabList = props.tabs.map((tab) => {
		if (tab.id === isActive) {
			return (
				<button className={styles.buttonActive} onClick={() => setIsActive(tab.id)}>
					{tab.button}
				</button>
			);
		}
		return (
			<button className={styles.button} onClick={() => setIsActive(tab.id)}>
				{tab.button}
			</button>
		);
	});

	let content = props.tabs.map((tab) => {
		if (tab.id === isActive) {
			return (
				<div id={tab.id} className={styles.active}>
					<div className={styles.tabContent} data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
						<h3 className="yell medium">{tab.name}</h3>
						{tab.content}
					</div>
				</div>
			);
		}
		return (
			<div id={tab.id} className={styles.notActive}>
				<div className={styles.tabContent} data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
					<h3 className="yell medium">{tab.name}</h3>
					{tab.content}
				</div>
			</div>
		);
	});

	return (
		<div id={props.id} className={styles.tabs} style={backgroundImageStyle}>
			<h2 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
				{props.title}
			</h2>
			<div className={styles.divider} />
			<div className="article row wrap">
				<div className={styles.buttons} data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
					{tabList}
				</div>
				<div className={styles.tabsContainer}>
					<div className={styles.container}>{content}</div>
				</div>
			</div>
		</div>
	);
}

export default Tabs;
